import React, { useState } from 'react';
import { Logout } from '@mui/icons-material';

export default function Sidebar({ componentArray, handleComponentClick, handleLogoutClick }) {
    const [activeComponent, setActiveComponent] = useState(null);

    const handleItemClick = (componentName) => {
        setActiveComponent(componentName);
        handleComponentClick(componentName);
    };

    return (
        <div className='my-2 sm:h-full'>
            <ul>
                {componentArray.map((component, index) => (
                    <li
                        key={index}
                        className={` cursor-pointer ${activeComponent === component.name ? ' bg-emerald-200 text-emerald-800 ' : ''}`}
                        onClick={() => handleItemClick(component.name)}
                    >
                        <div className="flex mx-5 items-center justify-between py-3 px-3">
                            <span className="flex gap-4 items-center">
                                {component.icon && <span className="mr-2">{component.icon}</span>}
                                <span>{component.name}</span>
                            </span>
                        </div>
                    </li>
                ))}
                <li className="hover:bg-gray-100 cursor-pointer" onClick={handleLogoutClick}>
                    <div className="flex mx-5 items-center justify-between py-3 px-3">
                        <span className="flex gap-4 items-center">
                            <Logout className='text-red-500' />
                            <span className='ml-2'>Logout</span>
                        </span>
                    </div>
                </li>
            </ul>
        </div>
    );
}
