import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/v1/",
    prepareHeaders: (headers) => {
      const token = Cookies.get("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Get all users
    getUsers: builder.query({
      query: () => "users",
    }),
    getEmpcode: builder.query({
      query: () => "users/empcode",
    }),
    getZones: builder.query({
      query: (location) => `zone/${location}`,
    }),
    getConting: builder.query({
      query: () => "getQuantityCount",
    }),
    getBreakdownHistory: builder.query({
      query: (machineNo) => `breakdown-history/${machineNo}`,
    }),
    getMachineList: builder.query({
      query: (empcode) => `machine_list/${empcode}`,
    }),
    getProductionHistory: builder.query({
      query: (machineNo) => `production-history/${machineNo}`,
    }),
    createUser: builder.mutation({
      query: (user) => ({
        url: "/user",
        method: "POST",
        body: user,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `/user/${userId}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    updateUser:builder.mutation({
      query:(data)=>({
        url:`/user-update/${data.ID}`,
        method:'PUT',
        body:data,
        headers: {
          "Content-Type": "application/json",
        },
      })
    })
  }),
});

export const {
  useGetZonesQuery,
  useGetContingQuery,
  useGetUsersQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useGetEmpcodeQuery,
  useGetBreakdownHistoryQuery,
  useGetMachineListQuery,
  useGetProductionHistoryQuery,
} = api;
