import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCreateUserMutation,useUpdateUserMutation, useGetZonesQuery, useGetUsersQuery } from '../../services/services';
import { CancelOutlined } from '@mui/icons-material';

const CreateUserForm = ({ setShowToast, handleCloseModal,user }) => {
    // const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        defaultValues:user || {
            status: 0,
            // other default values
        }
    });
    const {refetch}=useGetUsersQuery();
    const [location, setLocation] = useState('');
    const [formattedZoneData, setFormattedZoneData] = useState([]);

    const { data: zoneDatas, isFetching: isFetchingZones } = useGetZonesQuery(location);
    const [createUser] = useCreateUserMutation();
    const [updateUser] = useUpdateUserMutation();

    useEffect(() => {
        if (zoneDatas && zoneDatas.data && zoneDatas.data.length > 0) {
            const transformedData = zoneDatas.data.map((item) => ({
                value: item.code,
                label: item.code,
            }));
            setFormattedZoneData(transformedData);
        }
    }, [zoneDatas]);

    const onSubmit = async (data) => {
        try {
            if (user) {
                await updateUser(data);
                refetch();
                alert("User updated successfully");
            } else {
                await createUser(data);
                
                refetch();
                alert("User Created successfully");
            }
            // await createUser(data);
            setShowToast(true);
            refetch();
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
            handleCloseModal();
        } catch (error) {
            console.error("Error creating user:", error);
            alert("User Data not save");
            setShowToast(false); // Adjust this based on your error handling
        }
    };

    useEffect(() => {
        if (user) {
            // Populate form with user data
            Object.keys(user).forEach((key) => {
                setValue(key, user[key]);
            });
        }
    }, [user, setValue]);

    return (
        <div
            // onClick={handleCloseModal}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
            <div
                className="bg-white mx-2 rounded-xl shadow-xl max-w-md w-full p-4"
                onClick={(e) => e.stopPropagation()}
            >
                <div className=' flex justify-between items-start'>
                <h2 className="text-xl font-semibold mb-4">{user ? 'Edit User' : 'Create New User'}</h2>
                    <button
                        className=" hover:text-red-700 rounded-full  text-2xl font-bold  text-red-500 focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={handleCloseModal}
                    >
                        <CancelOutlined className=' w-8' />
                    </button>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className=' h-[70vh] overflow-y-scroll scrollthin'>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="Empcode">
                            Employee Code
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="Empcode"
                            type="number"
                            placeholder="Employee Code"
                            {...register('Empcode', { required: 'Employee Code is required' })}
                        />
                        {errors.Empcode && <p className="text-red-500 text-xs italic">{errors.Empcode.message}</p>}
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="Empname">
                            Name
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="Empname"
                            type="text"
                            placeholder="Name"
                            {...register('Empname', { required: 'Name is required' })}
                        />
                        {errors.Empname && <p className="text-red-500 text-xs italic">{errors.Empname.message}</p>}
                    </div>
           

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="Address">
                            Address
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="Address"
                            type="text"
                            placeholder="Address"
                            {...register('Address', { required: 'Address is required' })}
                        />
                        {errors.Address && <p className="text-red-500 text-xs italic">{errors.Address.message}</p>}
                    </div>
                    <div className="mb-4">
                        <input
                            type="hidden"
                            id="status"
                            value="0"
                            {...register('status', { value: '0' })}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="MoNo">
                            Mobile Number
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="MoNo"
                            type="number"
                            placeholder="Mobile Number"
                            {...register('MoNo', { required: 'Mobile Number is required' })}
                        />
                        {errors.MoNo && <p className="text-red-500 text-xs italic">{errors.MoNo.message}</p>}
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="MailId">
                            Email
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="MailId"
                            type="email"
                            placeholder="Email"
                            {...register('MailId', { required: 'Email is required' })}
                        />
                        {errors.MailId && <p className="text-red-500 text-xs italic">{errors.MailId.message}</p>}
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="Gender">
                            Gender
                        </label>
                        <select
                            id="Gender"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            {...register('Gender', { required: 'Gender is required' })}
                        >
                            <option value="">Select Gender</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                            <option value="Other">Other</option>
                        </select>
                        {errors.Gender && <p className="text-red-500 text-xs italic">{errors.Gender.message}</p>}
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="Username">
                            Username
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="Username"
                            type="text"
                            placeholder="Username"
                            {...register('Username', { required: 'Username is required' })}
                        />
                        {errors.Username && <p className="text-red-500 text-xs italic">{errors.Username.message}</p>}
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="Password">
                            Password
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="Password"
                            type="text"
                            placeholder="Password"
                            {...register('Password', { required: 'Password is required' })}
                        />
                        {errors.Password && <p className="text-red-500 text-xs italic">{errors.Password.message}</p>}
                    </div>


                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="Image">
                            Image
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="Image"
                            type="file"
                            placeholder="Image"
                            {...register('image')}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="designation">
                            Designation
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="designation"
                            type="text"
                            placeholder="Designation"
                            {...register('designation')}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="Location">
                            Location
                        </label>
                        <select
                            id="Location"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            {...register('Location', { required: 'Location is required' })}
                            onChange={(e) => setLocation(e.target.value)}
                        >
                            <option value="">Select Location</option>
                            <option value="Daman">Daman</option>
                            <option value="Umargam">Umargam</option>
                        </select>
                        {errors.Location && <p className="text-red-500 text-xs italic">{errors.Location.message}</p>}
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="U_Zone">
                            Zone
                        </label>
                        <select
                            id="U_Zone"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            {...register('U_Zone')}
                            multiple
                            disabled={isFetchingZones}
                        >
                            <option value="">Select Zone</option>
                            {formattedZoneData.map((zone) => (
                                <option key={zone.value} value={zone.value}>
                                    {zone.label}
                                </option>
                            ))}
                        </select>
                        {errors.U_Zone && <p className="text-red-500 text-xs italic">{errors.U_Zone.message}</p>}
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="Role">
                            Role
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="Role"
                            type="text"
                            placeholder="Role"
                            {...register('Role')}
                        />

                        {errors.Role && <p className="text-red-500 text-xs italic">{errors.Role.message}</p>}
                    </div>
                    <div className="flex items-center justify-between">

                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="submit"

                        >
                             {user ? 'Update' : 'Create'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateUserForm;

