import React,{useState} from 'react'
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDeleteUserMutation ,useGetUsersQuery} from '../../services/services';

import CreateUserForm from './CreateUserFrom';
export default function TableOne({ currentUsers, isLoadingUsers, isLoadingUsersError }) {
    const [deleteUser, { isLoading: isDeleting, isError: isDeleteError }] = useDeleteUserMutation();
    const [editingUser, setEditingUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const {refetch}=useGetUsersQuery();

    const handleEdit = (user) => {
        setEditingUser(user);
        setShowModal(true);
    };


    const handleDelete = async (empcode) => {
        try {
            await deleteUser(empcode).unwrap();

            refetch();
            alert(`User ${empcode} Delete Successful`);
            // Handle success, e.g., show a success message or refresh the user list
        } catch (error) {
            console.error('Failed to delete user:', error);
            // Handle error, e.g., show an error message
        }
    };

    const handleCloseModal = () => {
        setEditingUser(null);
        setShowModal(false);
    };

    return (
        <div className="p-1.5 col-span-1 scrollthin  min-w-full overflow-x-scroll inline-block align-middle">
            <div className="border rounded-lg divide-y divide-gray-200">
                <div className="">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className=" px-3   py-2  text-end text-xs font-medium text-gray-500 uppercase">Action</th>
                                <th scope="col" className=" px-3   py-2  text-start text-xs font-medium text-gray-500 uppercase">Name</th>
                                <th scope="col" className="px-6 hidden  py-3 text-start text-xs font-medium text-gray-500 uppercase">Age</th>
                                <th scope="col" className=" px-3   py-2  hidden md:block text-start text-xs font-medium text-gray-500 uppercase">Address</th>
                                <th scope="col" className=" px-3   py-2  text-start text-xs font-medium text-gray-500 uppercase">Empcode</th>
                                <th scope="col" className=" px-3   py-2  text-start text-xs font-medium text-gray-500 uppercase">Gender</th>
                                <th scope="col" className=" px-3   py-2  text-start text-xs font-medium text-gray-500 uppercase">MoNo</th>
                                <th scope="col" className=" px-3   py-2  text-start text-xs font-medium text-gray-500 uppercase">MailId</th>
                                <th scope="col" className=" px-3   py-2  text-start text-xs font-medium text-gray-500 uppercase">Username</th>
                                <th scope="col" className=" px-3   py-2  text-start text-xs font-medium text-gray-500 uppercase">Password</th>
                                <th scope="col" className=" px-3   py-2  text-start text-xs font-medium text-gray-500 uppercase">Location</th>
                                <th scope="col" className=" px-3   py-2  text-start text-xs font-medium text-gray-500 uppercase">Status</th>
                                <th scope="col" className=" px-3   py-2  text-start hidden md:block text-xs font-medium text-gray-500 uppercase">Designation</th>
                                <th scope="col" className=" px-3   py-2  text-start text-xs font-medium text-gray-500 uppercase">U_Zone</th>
                                <th scope="col" className=" px-3   py-2  text-start text-xs hidden md:block font-medium text-gray-500 uppercase">Role</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {currentUsers.map((user) => (
                                <tr key={user.ID}>
                                    <td className=" px-2  py-2  whitespace-nowrap text-end text-sm font-medium">
                                        <button type="button" onClick={() => handleEdit(user)} className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 focus:outline-none focus:text-blue-800 disabled:opacity-50 disabled:pointer-events-none">
                                            <EditIcon />
                                        </button>
                                        <button type="button"
                                            onClick={() => handleDelete(user.ID)}
                                            className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-600 hover:text-red-800 focus:outline-none focus:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                                            <DeleteIcon />
                                        </button>
                                    </td>
                                    <td className=" px-2  py-2  whitespace-nowrap text-sm font-medium text-gray-800">{user.Empname}</td>
                                    <td className=" px-2  py-2  whitespace-nowrap text-sm text-gray-800 hidden md:block">{user.Age}</td>
                                    <td className=" px-2  py-2  whitespace-nowrap text-sm text-gray-800 hidden md:block">{user.Address}</td>
                                    <td className=" px-2  py-2  whitespace-nowrap text-sm text-gray-800">{user.Empcode}</td>
                                    <td className=" px-2  py-2  whitespace-nowrap text-sm text-gray-800">{user.Gender}</td>
                                    <td className=" px-2  py-2  whitespace-nowrap text-sm text-gray-800">{user.MoNo}</td>
                                    <td className=" px-2  py-2  whitespace-nowrap text-sm text-gray-800">{user.MailId}</td>
                                    <td className=" px-2  py-2  whitespace-nowrap text-sm text-gray-800">{user.Username}</td>
                                    <td className=" px-2  py-2  whitespace-nowrap text-sm text-gray-800">{user.Password}</td>
                                    <td className=" px-2  py-2  whitespace-nowrap text-sm text-gray-800">{user.Location}</td>
                                    <td className=" px-2  py-2  whitespace-nowrap text-sm text-gray-800">{user.status}</td>
                                    <td className=" px-2  py-2  whitespace-nowrap text-sm text-gray-800 hidden md:block">{user.designation}</td>
                                    <td className=" px-2  py-2  whitespace-wrap text-sm text-gray-800">{user.U_Zone}</td>
                                    <td className=" px-2  py-2  whitespace-nowrap text-sm text-gray-800 hidden md:block">{user.Role}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {isLoadingUsers && <p>Loading...</p>}
                    {isLoadingUsersError && <p>Error loading users.</p>}
                    {isDeleting && <p>Deleting...</p>}
                    {isDeleteError && <p>Error deleting user.</p>}
                </div>

            </div>
            {showModal && (
                <CreateUserForm
                    setShowToast={() => {}}
                    handleCloseModal={handleCloseModal}
                    refetch={() => {}}
                    user={editingUser}
                />
            )}

        </div>

    )
}
