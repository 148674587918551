import axios from "axios";
import Cookies from "js-cookie";
// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: "/api/v1",
});

// Add a request interceptor to include the token in headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("token"); // Assume token is stored in localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
