import React, { useState } from "react";
import axios from "axios";

const UserUpdate = ({ handleClose }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      alert("Please select a file to upload");
      return;
    }

    const formData = new FormData();
    formData.append("csvFile", file);

    try {
      setLoading(true);
      const response = await axios.post("/api/v1/uploadUsers", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert(response.data.message);
      handleClose();
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="max-w-sm min-w-96 " onSubmit={handleSubmit}>
      <label htmlFor="file-input" className="sr-only">
        Choose file
      </label>
      <input
        type="file"
        name="file-input"
        id="file-input"
        className="block  border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none file:bg-gray-50 file:border-0 file:me-4 file:py-3 file:px-4"
        onChange={handleFileChange}
      />
      <button
        type="submit"
        variant="contained"
        color="primary"
        disabled={loading}
        className="mt-4 py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
      >
        {loading ? "Uploading..." : "Upload"}
      </button>
    </form>
  );
};

export default UserUpdate;
