import React from "react";
import ProductionHistory from "./history/ProductionHistory";
import { useGetContingQuery } from "../../services/services";
import {
  ProductionQuantityLimits,
  ReportProblem,
  People,
} from "@mui/icons-material";

export default function Home({ user }) {
  const { data: quantityData } = useGetContingQuery();

  return (
    <div className=" min-h-screen py-6">
      <div className="container mx-auto">
        <div className="bg-white rounded-lg shadow-md px-6 pb-6">
          {quantityData && (
            <>
              <h1 className="text-2xl font-bold text-gray-800 mb-4">
                Dashboard
              </h1>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="bg-blue-100 p-4 rounded-lg flex items-center">
                  <ProductionQuantityLimits className="text-blue-500 mr-4" />
                  <div>
                    <p className="text-lg font-semibold text-gray-700">
                      Total Production Quantity
                    </p>
                    <p className="text-xl font-bold text-gray-900">
                      {quantityData.total_production_qty}
                    </p>
                  </div>
                </div>
                <div className="bg-red-100 p-4 rounded-lg flex items-center">
                  <ReportProblem className="text-red-500 mr-4" />
                  <div>
                    <p className="text-lg font-semibold text-gray-700">
                      Total Rejection Quantity
                    </p>
                    <p className="text-xl font-bold text-gray-900">
                      {quantityData.total_rejection_qty}
                    </p>
                  </div>
                </div>
                <div className="bg-green-100 p-4 rounded-lg flex items-center">
                  <People className="text-green-500 mr-4" />
                  <div>
                    <p className="text-lg font-semibold text-gray-700">
                      Total Users
                    </p>
                    <p className="text-xl font-bold text-gray-900">
                      {quantityData.total_user_qty}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className=" mt-5">
          <h2 className=" font-bold text-xl my-1">Production History</h2>
          <ProductionHistory />
        </div>
      </div>
    </div>
  );
}
