import React, { useState } from "react";
import { Button, Popover } from "@mui/material";
import UserUpdate from "./UserUpdate.jsx"; // Ensure this path is correct

const UserCsv = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "zone-update-popover" : undefined;

  return (
    <>
      <Button variant="contained" onClick={handleClick}>
        Import User
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className="w-[26rem]"
      >
        <div className="bg-blue h-96 p-9">
          <UserUpdate handleClose={handleClose} />
        </div>
      </Popover>
    </>
  );
};

export default UserCsv;
