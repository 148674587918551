import React, { useState } from "react";
import { CloseRounded } from "@mui/icons-material";
import User from '../admin/User.jsx'
import Sidebar from "../seller/Sidebar.jsx";
import Home from "./Home.jsx";
import UserManagement from "./UserManagement.jsx";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import Navbar from "../seller/Navbar.jsx";
import PersonIcon from "@mui/icons-material/Person";
import Cookies from "js-cookie";
import Historys from "./history/History.jsx";
import Logo from "../../assets/logo.jpg";
import { History } from "@mui/icons-material";
export default function AdminHome() {
  const [activeComponent, setActiveComponent] = useState("Dashboard");
  const [showSidebar, setShowSidebar] = useState(false);

  const handleComponentClick = (componentName) => {
    setActiveComponent(componentName);
  };
  const handleLogoutClick = (userType) => {
    Cookies.remove("token");
    window.location.href = "/";
  };
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const componentArray = [
    {
      name: "Dashboard",
      path: "/dashboard",
      icon: <SpaceDashboardIcon />,
    },

    {
      name: "User Management",
      path: "/user-management",
      icon: <PersonIcon />,
    },
    {
      name: "History",
      path: "/user-managemen",
      icon: <History />,
    },
  ];

  return (
    <>
      <div className="flex">
        <div
          className={`w-64 overflow-hidden lg:w-72 ${
            showSidebar ? "block" : "hidden"
          } h-screen bg-[#fff] shadow-2xl mr-2 md:block absolute lg:relative top-0 left-0 z-50 transition-all duration-300 ease-in-out`}
        >
          <aside className="h-full">
            <div className="flex justify-end px-4 py-2 lg:hidden">
              <button onClick={() => setShowSidebar(!showSidebar)}>
                <CloseRounded className="w-6 h-6 text-gray-500" />
              </button>
            </div>
            <div className="  flex items-center justify-center border-b-[1px]">
              <img src={Logo} className="  w-16" alt="vardhman logo" />
            </div>
            <Sidebar
              componentArray={componentArray}
              handleComponentClick={handleComponentClick}
              handleLogoutClick={() => handleLogoutClick("token")}
            />
          </aside>
        </div>
        <div className="flex-1 w-1/2">
          <Navbar toggleSidebar={toggleSidebar} />
          <div className="flex-1 h-[85vh] overflow-y-auto  p-3 md:px-8 py-2">
            {activeComponent === "Dashboard" && <Home />}
            {activeComponent === "User Management" && <User />}
            {activeComponent === "History" && <Historys />}
          
          </div>
        </div>
      </div>
      {/* {!isLoggedIn && (
                <Navigate to="/admin" replace={true} />
            )} */}
    </>
  );
}
