import React, { useMemo, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useGetProductionHistoryQuery } from "../../../services/services";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { Button, Box } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const ProductionHistory = ({ selectedMachine }) => {
  // Fetch production history
  const {
    data: productionHistory = [],
    isError: isLoadingHistoryError,
    isFetching: isFetchingHistory,
    isLoading: isLoadingHistory,
  } = useGetProductionHistoryQuery(selectedMachine || "BM001");

  

  const columns = useMemo(
    () => [
      {
        accessorKey: "DocDetEntry",
        header: "DocDetEntry",
      },
      {
        accessorKey: "Category",
        header: "Category",
      },
      {
        accessorKey: "Subcategory",
        header: "Subcategory",
      },
      {
        accessorKey: "Qty",
        header: "Quantity",
      },
      {
        accessorKey: "CreatedDate",
        header: "Created Date",
      },
    ],
    []
  );
  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  // Ensure productionHistory is an array and has data
  const data = Array.isArray(productionHistory.currentDate)
    ? productionHistory.currentDate
    : [];
  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };
  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    columnFilterDisplayMode: 'popover',
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    initialState: { density: "compact" },
    getRowId: (row) => row.DocDetEntry,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export All Rows
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Page Rows
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  if (isLoadingHistory || isFetchingHistory) {
    return <div>Loading...</div>;
  }

  if (isLoadingHistoryError) {
    return <div>Error loading production history</div>;
  }

  return <MaterialReactTable table={table} />;
};

export default ProductionHistory;
