import React, { useState, useEffect } from 'react';
import { useGetUsersQuery } from "../../services/services";
import TableOne from './TableOne';
import CreateUserForm from './CreateUserFrom';
import Toast from './Toast';
import UserCsv from './UserCsv';
const User = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const {
    data: fetchedUsers = [],
    isError: isLoadingUsersError,
    isLoading: isLoadingUsers,
    refetch,
  } = useGetUsersQuery();

  useEffect(() => {
    refetch();
  }, [fetchedUsers, refetch]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredUsers = fetchedUsers.filter(user =>
    user.Empname.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.Address.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const handlePrevPage = () => {
    setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => (prevPage < totalPages ? prevPage + 1 : prevPage));
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {showToast && (
        <Toast
          message={"User added successfully"}
          type={'success'}
          onClose={() => setShowToast(false)}
        />
      )}
      <div className="flex flex-col shadow-2xl mt-4">
        <div className="-m-1.5 grid grid-cols-1 overflow-hidden">
          <div className="py-3 px-4 flex justify-start gap-3">
            <div className="relative max-w-xs ">
              <label htmlFor="hs-table-search" className="sr-only">Search</label>
              <input
                type="text"
                name="hs-table-search"
                id="hs-table-search"
                className="py-2 px-3 ps-9 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none border outline-none"
                placeholder="Search for items"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-3">
                <svg className="size-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <circle cx="11" cy="11" r="8"></circle>
                  <path d="m21 21-4.3-4.3"></path>
                </svg>
              </div>
            </div>
            <button
              onClick={handleOpenModal}
              className="inline-flex border items-center gap-x-2 text-sm font-semibold rounded-lg bg-blue-600 text-white hover:text-blue-800 focus:outline-none px-4 disabled:opacity-50 disabled:pointer-events-none"
            >
              New User
            </button>
            <UserCsv/>
          </div>
          <TableOne refetch={refetch} currentUsers={currentUsers} isLoadingUsers={isLoadingUsers} isLoadingUsersError={isLoadingUsersError} />
          <div className="py-3 px-4 flex justify-between items-center">
            <button
              className="inline-flex p-3 bg-gray-500 text-black border items-center gap-x-2 text-sm font-semibold rounded-lg focus:outline-none focus:text-gray-800 disabled:opacity-50 disabled:pointer-events-none"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="inline-flex p-3 bg-emerald-500 text-white border outline-none items-center gap-x-2 text-sm font-semibold rounded-l rounded-md focus:outline-none focus:text-gray-800 disabled:opacity-50 disabled:pointer-events-none"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <CreateUserForm refetch={refetch} setShowToast={setShowToast} isModalOpen={isModalOpen} handleCloseModal={handleCloseModal} />
      )}
    </>
  );
};

export default User;
