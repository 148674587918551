import React, { useState } from "react";
import {
  useGetEmpcodeQuery,
  useGetMachineListQuery,
} from "../../../services/services";
import ProductionHistory from "./ProductionHistory";
import BreakDownHistoy from "./BreakDownHistoy.jsx";

export default function History() {
  const {
    data: empcodeData,

    isError: empcodeError,
  } = useGetEmpcodeQuery();
  const [selectedEmpcode, setSelectedEmpcode] = useState(null);
  const [selectedMachine, setSelectedMachine] = useState(null);

  const { data: machineListData } = useGetMachineListQuery(
    selectedEmpcode || "5"
  );

  const handleEmpcodeChange = (e) => {
    setSelectedEmpcode(e.target.value);
    setSelectedMachine(null); // Reset machine selection when employee changes
  };

  const handleMachineChange = (e) => {
    setSelectedMachine(e.target.value);
  };

  if (empcodeError) {
    return <p>Something Errer</p>;
  }
  return (
    <div className="container mx-auto ">
      <div className="bg-white rounded-lg shadow-md p-6">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">History</h1>
        <div className=" flex gap-5">
          <div className="mb-4 bg-white">
            <label htmlFor="empcode" className="block text-gray-700">
              Select Employee Code
            </label>
            <select
              id="empcode"
              className="mt-1 block w-full  py-3  px-2 border border-gray-300 shadow-sm bg-white"
              onChange={handleEmpcodeChange}
            >
              <option value="">Select Employee</option>
              {empcodeData?.map((emp) => (
                <option key={emp.Empcode} value={emp.Empcode}>
                  {emp.Empcode} ({emp.Empname})
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4 bg-white">
            <label htmlFor="machine" className="block text-gray-700">
              Select Machine
            </label>
            <select
              id="machine"
              className="mt-1 block w-full  py-3  px-2 border border-gray-300 shadow-sm bg-white"
              onChange={handleMachineChange}
            >
              <option value="">Select Machine</option>
              {machineListData?.map((machine) => (
                <option key={machine.Code} value={machine.Code}>
                  {machine.Code}
                </option>
              ))}
              {machineListData?.length < 0 && <p>hello</p>}
            </select>
          </div>
        </div>

        <div>
          <h2 className=" my-2 text-lg font-bold">Production History</h2>
          <ProductionHistory selectedMachine={selectedMachine} />
          <h2 className=" my-2 text-lg font-bold">Break Down History</h2>
          <BreakDownHistoy selectedMachine={selectedMachine} />
        </div>
      </div>
    </div>
  );
}
