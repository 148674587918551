import React, { useEffect, useState } from "react";
import AdminHome from "./component/admin/AdminHome";
import Login from "./component/seller/Login";
import Cookies from "js-cookie";
import axios from "axios";

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      verifyToken(token);
    }
  }, []);

  const verifyToken = async (token) => {
    try {
      const response = await axios.get(
        "/api/v1/admin/verifyToken",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setIsLoggedIn(true);
      }
    } catch (error) {
      console.error("Token verification failed:", error);
      setIsLoggedIn(false);
    }
  };

  return <div>{isLoggedIn ? <AdminHome /> : <Login />}</div>;
}
