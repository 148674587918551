// Toast.js
import React from 'react';

const Toast = ({ message, type, onClose }) => {
  const getTypeStyles = () => {
    switch (type) {
      case 'success':
        return 'bg-teal-500 text-white';
      case 'error':
        return 'bg-red-500 text-white';
      case 'warning':
        return 'bg-yellow-500 text-white';
      default:
        return 'bg-gray-200 text-gray-800';
    }
  };

  return (
    <div
      className={`max-w-xs border border-gray-200 rounded-xl shadow-lg ${getTypeStyles()} dark:bg-neutral-800 dark:border-neutral-700`}
      role="alert"
      tabIndex="-1"
      aria-labelledby="toast-label"
    >
      <div className="flex p-4">
        <div className="shrink-0">
          <svg
            className={`shrink-0 size-4 ${type === 'success' ? 'text-teal-500' : type === 'error' ? 'text-red-500' : type === 'warning' ? 'text-yellow-500' : 'text-gray-500'} mt-0.5`}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            {type === 'success' && (
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
            )}
            {type === 'error' && (
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"></path>
            )}
            {type === 'warning' && (
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
            )}
          </svg>
        </div>
        <div className="ms-3">
          <p id="toast-label" className="text-sm">
            {message}
          </p>
        </div>
        <button onClick={onClose} className="ml-auto text-gray-400 hover:text-gray-500 dark:text-neutral-400 dark:hover:text-neutral-300">
          <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
            <path d="M11.354 4.646a.5.5 0 0 0-.708.708L12.293 8l-1.647 1.646a.5.5 0 1 0 .708.708L13 8.707l1.646 1.647a.5.5 0 0 0 .708-.708L13.707 8l1.646-1.646a.5.5 0 0 0-.708-.708L13 7.293 11.354 4.646z"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Toast;
