import { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axiosInstance from "./api/axiosInstance"; // Import the configured Axios instance
import UserCsv from "./UserCsv";
import {
  useGetZones,
  useCreateUser,
  useDeleteUser,
  useUpdateUser,
} from "./api/apiData";
import { mkConfig, generateCsv, download } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ZoneUpdateForm from "./zone/ZoneUpdateForm";
import { useGetUsersQuery } from "../../services/services";
const Example = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const [file, setFile] = useState(null);

  const {
    data: fetchedUsers = [],
    isError: isLoadingUsersError,
    isFetching: isFetchingUsers,
    isLoading: isLoadingUsers,
    refetch,
  } = useGetUsersQuery();
  useEffect(() => {
    // Refetch the data from the server when the component state changes
    refetch();
  }, [fetchedUsers]);

  const { data: zoneDatas } = useGetZones();
  // State to hold the transformed zone data
  const [formattedZoneData, setFormattedZoneData] = useState([]);

  useEffect(() => {
    // Check if zoneDatas is available and not empty before transforming
    if (zoneDatas && zoneDatas.data && zoneDatas.data.length > 0) {
      const transformedData = zoneDatas.data.map((item) => ({
        value: item.code,
        label: item.code,
      }));
      setFormattedZoneData(transformedData);
    }
  }, [zoneDatas]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "ID",
        header: "ID",
        enableEditing: false,
        size: 80,
      },

      {
        accessorKey: "Empcode",
        header: "EmpCode",
        enableEditing: true,
        size: 50,
      },
      {
        accessorKey: "Image",
        header: "Image",
        Cell: ({ cell }) =>
          cell.getValue() ? (
            <img
              src={`/api/v1/image/${cell.getValue()}`}
              alt="User Profile"
              width="80"
              height="80"
            />
          ) : (
            "No Image"
          ),

        muiEditTextFieldProps: ({ cell }) => ({
          type: cell.value ? "text" : "file",
          error: !!validationErrors?.Image,
          helperText: validationErrors?.Image,
          inputProps: {
            value: cell.value ? URL.createObjectURL(cell.value) : "",
          },
          onChange: (e) => {
            if (!cell.value) {
              setFile(e.target.files[0]);
            }
          },
          onFocus: () =>
            setValidationErrors((prev) => ({
              ...prev,
              Image: undefined,
            })),
        }),
        size: 50,
      },

      {
        accessorKey: "Empname",
        header: "Employee Name",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.Empname,
          helperText: validationErrors?.Empname,
          onFocus: () =>
            setValidationErrors((prev) => ({
              ...prev,
              Empname: undefined,
            })),
        },
        size: 50,
      },
      {
        accessorKey: "Address",
        header: "Address",
        muiEditTextFieldProps: {
          error: !!validationErrors?.Address,
          helperText: validationErrors?.Address,
          onFocus: () =>
            setValidationErrors((prev) => ({
              ...prev,
              Address: undefined,
            })),
        },
        size: 50,
      },
      {
        accessorKey: "Gender",
        header: "Gender",
        muiEditTextFieldProps: {
          error: !!validationErrors?.Gender,
          helperText: validationErrors?.Gender,
          onFocus: () =>
            setValidationErrors((prev) => ({
              ...prev,
              Gender: undefined,
            })),
        },
        size: 50,
      },
      {
        accessorKey: "MoNo",
        header: "Mobile Number",
        muiEditTextFieldProps: {
          error: !!validationErrors?.MoNo,
          helperText: validationErrors?.MoNo,
          onFocus: () =>
            setValidationErrors((prev) => ({
              ...prev,
              MoNo: undefined,
            })),
        },
        size: 50,
      },
      {
        accessorKey: "MailId",
        header: "Email",
        muiEditTextFieldProps: {
          type: "email",
          required: true,
          error: !!validationErrors?.MailId,
          helperText: validationErrors?.MailId,
          onFocus: () =>
            setValidationErrors((prev) => ({
              ...prev,
              MailId: undefined,
            })),
        },
        size: 50,
      },
      {
        accessorKey: "Username",
        header: "Username",
        muiEditTextFieldProps: {
          error: !!validationErrors?.Username,
          helperText: validationErrors?.Username,
          onFocus: () =>
            setValidationErrors((prev) => ({
              ...prev,
              Username: undefined,
            })),
        },
        size: 50,
      },
      {
        accessorKey: "Password",
        header: "Password",
        muiEditTextFieldProps: {
          type: "text",
          error: !!validationErrors?.Password,
          helperText: validationErrors?.Password,
          onFocus: () =>
            setValidationErrors((prev) => ({
              ...prev,
              Password: undefined,
            })),
        },
        size: 50,
      },
      {
        accessorKey: "Location",
        header: "Location",
        muiEditTextFieldProps: {
          error: !!validationErrors?.Location,
          helperText: validationErrors?.Location,
          onFocus: () =>
            setValidationErrors((prev) => ({
              ...prev,
              Location: undefined,
            })),
        },
        size: 50,
      },
      {
        accessorKey: "status",
        header: "Status",
        muiEditTextFieldProps: {
          error: !!validationErrors?.status,
          helperText: validationErrors?.status,
          onFocus: () =>
            setValidationErrors((prev) => ({
              ...prev,
              status: undefined,
            })),
        },
        size: 50,
      },

      {
        accessorKey: "U_Zone",
        header: "Zone",
        editVariant: "select",

        Edit: (props) => <ZoneUpdateForm {...props} />,

        size: 50,
      },

      {
        accessorKey: "Role",
        header: "Role",
        muiEditTextFieldProps: {
          error: !!validationErrors?.Role,
          helperText: validationErrors?.Role,
          onFocus: () =>
            setValidationErrors((prev) => ({
              ...prev,
              Role: undefined,
            })),
        },
        size: 50,
      },
    ],
    [formattedZoneData, validationErrors]
  );

  const { mutateAsync: createUser, isPending: isCreatingUser } =
    useCreateUser();
  const { mutateAsync: updateUser, isPending: isUpdatingUser } =
    useUpdateUser();
  const { mutateAsync: deleteUser, isPending: isDeletingUser } =
    useDeleteUser();

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axiosInstance.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data.filename;
  };

  const handleCreateUser = async ({ values, table }) => {
    const newValidationErrors = validateUser(values);

    setValidationErrors({});
    if (file) {
      const fileName = await uploadFile(file);
      values.Image = fileName;
      setFile("");
    }
    await createUser(values);
    refetch();
    table.setCreatingRow(null);
  };

  const handleSaveUser = async ({ values, table }) => {
    if (file) {
      const fileName = await uploadFile(file);
      values.Image = fileName;
      setFile("");
    }
    await updateUser(values);
    refetch();
    table.setEditingRow(null);
  };

  const openDeleteConfirmModal = async (row) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      await deleteUser(row.original.ID);
      table.setEditingRow(null);
      refetch();
    }
  };
  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };
  const data = fetchedUsers;
  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    initialState: { density: "compact" },
    // createDisplayMode: "row",
    // editDisplayMode: "row",
    enableEditing: true,
    getRowId: (row) => row.ID,
    muiToolbarAlertBannerProps: isLoadingUsersError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateUser,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser,

    onEditingRowSave: handleSaveUser,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button onClick={() => refetch()}>
          <img
            srcSet={
              "https://img.icons8.com/?size=100&id=4BuwPT7Np2AI&format=png&color=000000"
            }
            src="https://img.icons8.com/?size=100&id=4BuwPT7Np2AI&format=png&color=000000"
            alt="refresh"
            className={`w-6 h-6 ${
              isFetchingUsers ? "animate-spin" : "animate-none"
            }`}
          />
        </Button>
        <div className="gap-3 flex">
          <UserCsv />
        </div>
        <div className=" gap-3 flex">
          <Button
            variant="contained"
            onClick={() => {
              table.setCreatingRow(true);
            }}
          >
            Create New User
          </Button>
        </div>
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>
        {/* <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export All Rows
        </Button> */}
        {/* <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Page Rows
        </Button> */}
        {/* <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button> */}
      </Box>
    ),
    state: {
      isLoading: isLoadingUsers,
      isSaving: isCreatingUser || isUpdatingUser || isDeletingUser,
      showAlertBanner: isLoadingUsersError,
      showProgressBars: isFetchingUsers,
    },
  });

  return <MaterialReactTable table={table} />;
};

const queryClient = new QueryClient();

const UserManagement = () => (
  <QueryClientProvider client={queryClient}>
    <Example />
  </QueryClientProvider>
);

export default UserManagement;

const validateRequired = (value) => !!value;
const validateEmail = (email) =>
  !!email &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

function validateUser(user) {
  return {
    Empcode: !validateRequired(user.Empcode) ? "Empcode is Required" : "",
    Password: !validateRequired(user.Password) ? "Password is Required" : "",
    MailId: !validateEmail(user.MailId) ? "Incorrect Email Format" : "",
    U_Zone: !validateRequired(user.U_Zone) ? "Invalid U_Zone" : "",
  };
}
